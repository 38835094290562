import { Card, StaggerItem, DetailsModal } from ".";
import { useDisclosure } from "@nextui-org/react";
import { useState } from "react";

interface Movie {
    imdbmovieid: string;
    movietitle: string;
    moviemainphotos: string[];
    movielanguages: string[];
    moviegenres: string[];
    moviecountries: string[];
}

interface MoviesListProps {
    movies: Movie[];
}

export default function MoviesList({ movies }: MoviesListProps) {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [modalData, setModalData] = useState<Movie | null>(null);

    function handleCardClick(movie: Movie) {
        setModalData(movie);
        onOpen();
    }

    return (
        <div className="flex flex-wrap gap-10 justify-center">
            {movies?.map((movie, index: number) => (
                <StaggerItem index={index} key={movie.imdbmovieid}>
                    <Card
                        title={movie?.movietitle}
                        coverImg={movie?.moviemainphotos[0]}
                        languages={movie?.movielanguages}
                        genres={movie?.moviegenres}
                        countries={movie?.moviecountries}
                        onClick={() => handleCardClick(movie)}
                    />
                </StaggerItem>
            ))}
            <DetailsModal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                data={modalData}
            />
        </div>
    );
}
