interface MovieCardProps {
    title: string;
    coverImg: string;
    languages: string[];
    genres: string[];
    countries: string[];
    onClick: () => void;
}

export default function MovieCard(props: MovieCardProps) {
    const { title, coverImg, onClick } = props;
    return (
        <img
            alt={title}
            className="h-full w-full object-cover cursor-pointer hover:scale-105 max-w-[281px] rounded-lg transition-all duration-300 ease-in-out"
            src={coverImg}
            onClick={onClick}
        />
    );
}
