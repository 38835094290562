import "./App.css";
import { Header, Page } from "./components";
import { NextUIProvider } from "@nextui-org/react";

export default function App() {
  return (
    <NextUIProvider className="dark text-foreground bg-background min-h-[100vh]">
      <Header />
      <Page />
    </NextUIProvider>
  );
}
