import { Pagination as NextUIPagination } from "@nextui-org/react";

interface PaginationProps {
    total: number;
    initialPage: number;
    onChange: (page: number) => void;
    showControls?: boolean;
}

export default function Pagination(props: PaginationProps) {
    return (
        <NextUIPagination
            {...props}
            variant="faded"
            color="default"
            classNames={{
                wrapper: "m-auto",
            }}
        />
    );
}
