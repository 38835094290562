import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
} from "@nextui-org/react";

interface Movie {
    imdbmovieid: string;
    movietitle: string;
    moviemainphotos: string[];
    movielanguages: string[];
    moviegenres: string[];
    moviecountries: string[];
}

interface DetailsModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    data: Movie | null;
}

export default function DetailsModal(props: DetailsModalProps) {
    const { isOpen, onOpenChange, data } = props;
    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            placement="center"
            className="dark text-foreground bg-background"
            backdrop="blur"
            classNames={{
                closeButton: "text-foreground z-10 bg-background",
            }}
            radius="sm"
        >
            <ModalContent>
                <ModalHeader className="flex flex-col gap-1">
                    {data?.movietitle}
                </ModalHeader>
                <ModalBody>
                    <div className="flex flex-wrap justify-center md:justify-start sm:flex-nowrap gap-5">
                        <img
                            alt={data?.movietitle}
                            className="h-full w-[150px] object-cover rounded-sm"
                            src={data?.moviemainphotos[0]}
                        />
                        <div className="flex flex-col gap-2">
                            <p>
                                <strong>Languages:</strong> {data?.movielanguages.join(", ")}
                            </p>
                            <p>
                                <strong>Genres:</strong> {data?.moviegenres.join(", ")}
                            </p>
                            <p>
                                <strong>Countries:</strong> {data?.moviecountries.join(", ")}
                            </p>
                        </div>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
