import {
  Container,
  Pagination,
  MoviesList,
  FilterInput,
  useMovieOptions,
} from ".";
import data from "../movieData.json";
import { useState, useMemo } from "react";
import { Button } from "@nextui-org/react";

const ITEMS_PER_PAGE = 20;

interface Filters {
  language: string[];
  genre: string[];
  country: string[];
}

const initialFilters = {
  language: [],
  genre: [],
  country: [],
};

export default function Page() {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const { filtersMapping } = useMovieOptions();

  function handleFilterChange(key: keyof Filters, value: string[]) {
    const filteredValue = value.filter((v) => v !== "");
    setFilters((prev) => ({ ...prev, [key]: filteredValue }));
    setCurrentPage(1);
  }

  function handlePageChange(page: number) {
    window.scrollTo(0, 0);
    setCurrentPage(page);
  }

  function handleFilterReset() {
    setFilters(initialFilters);
    setCurrentPage(1);
  }

  const filteredData = useMemo(() => {
    if (
      !filters.language.length &&
      !filters.genre.length &&
      !filters.country.length
    )
      return data;
    let newData = data;

    if (filters.language.length) {
      newData = newData.filter((movie) =>
        filters.language.some((language) =>
          movie.movielanguages.includes(language)
        )
      );
    }
    if (filters.genre.length) {
      newData = newData.filter((movie) =>
        filters.genre.some((genre) => movie.moviegenres.includes(genre))
      );
    }
    if (filters.country.length) {
      newData = newData.filter((movie) =>
        filters.country.some((country) => movie.moviecountries.includes(country))
      );
    }

    return newData;
  }, [filters]);

  const showPagination = filteredData.length > ITEMS_PER_PAGE;

  return (
    <Container>
      <div className="py-10">
        <div className="flex flex-wrap gap-5 mx-auto mb-10 justify-center items-center">
          {filtersMapping.map((filter) => (
            <FilterInput
              key={filter.key}
              label={filter.label}
              placeholder={filter.placeholder}
              options={filter.options}
              selectedFilters={filters[filter.key as keyof Filters]}
              setAllFilters={(value: string[]) =>
                handleFilterChange(filter.key as keyof Filters, value)
              }
            />
          ))}
          <Button
            onClick={handleFilterReset}
            color="warning"
            variant="bordered"
            className="font-bold"
          >
            Clear Filters
          </Button>
        </div>

        <div className="flex flex-wrap gap-10">
          {
            filteredData.length ? (
              <MoviesList
                movies={filteredData?.slice(
                  (currentPage - 1) * ITEMS_PER_PAGE,
                  currentPage * ITEMS_PER_PAGE
                )}
              />
            ) : (
              <div className="text-center w-full">
                <h1 className="text-2xl">No movies found</h1>
              </div>
            )
          }
        </div>
        {
          showPagination && (
            <div className="mt-10">
              <Pagination
                showControls
                total={Math.ceil(filteredData?.length / ITEMS_PER_PAGE)}
                initialPage={currentPage}
                onChange={handlePageChange}
              />
            </div>
          )
        }
      </div>
    </Container>
  );
}
