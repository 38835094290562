import { Select, SelectItem } from "@nextui-org/react";

interface FilterInputProps {
    options: string[];
    selectedFilters: string[];
    setAllFilters: (filters: string[]) => void;
    label: string;
    placeholder: string;
}

export default function FilterInput({
    options,
    selectedFilters,
    setAllFilters,
    label,
    placeholder,
}: FilterInputProps) {
    return (
        <Select
            label={label}
            selectionMode="multiple"
            placeholder={placeholder}
            selectedKeys={selectedFilters}
            className="max-w-xs"
            onChange={(e) => {
                setAllFilters(e.target.value.split(","))
            }}
        >
            {options.map((animal) => (
                <SelectItem key={animal} value={selectedFilters}>
                    {animal}
                </SelectItem>
            ))}
        </Select>
    );
}
