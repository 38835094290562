import { useState, useEffect } from "react";
import data from "../movieData.json";

interface FilterOption {
    key: string;
    label: string;
    placeholder: string;
    options: string[];
}

const useMovieOptions = () => {

    const [filtersMapping, setFiltersMapping] = useState<FilterOption[]>([]);

    useEffect(() => {
        const languages = Array.from(
            new Set(data.map((movie) => movie.movielanguages).flat())
        );

        const genres = Array.from(
            new Set(data.map((movie) => movie.moviegenres).flat())
        );

        const countries = Array.from(
            new Set(data.map((movie) => movie.moviecountries).flat())
        );

        setFiltersMapping([
            {
                key: "language",
                label: "Language",
                placeholder: "Select language",
                options: languages,
            },
            {
                key: "genre",
                label: "Genre",
                placeholder: "Select genre",
                options: genres,
            },
            {
                key: "country",
                label: "Country",
                placeholder: "Select country",
                options: countries,
            },
        ]);
    }, []);

    return { filtersMapping };
};

export default useMovieOptions;
