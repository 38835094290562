import { Navbar, NavbarContent } from "@nextui-org/react";

export default function Header() {
  function handleClick() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  return (
    <Navbar
      shouldHideOnScroll
      isBordered
      classNames={{
        wrapper: "max-w-[1280px]",
      }}
    >
      <NavbarContent justify="center" className="w-full">
        <h3 className="text-xl font-bold" role="button" onClick={handleClick}>
          Assignment - ITion Solutions
        </h3>
      </NavbarContent>
    </Navbar>
  );
}
